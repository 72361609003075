/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    div: "div",
    strong: "strong"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 275px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 66.26506024096386%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAwAF/8QAFgEBAQEAAAAAAAAAAAAAAAAAAgED/9oADAMBAAIQAxAAAAHOYU1I1Kf/xAAZEAEBAAMBAAAAAAAAAAAAAAACAQADERL/2gAIAQEAAQUCM7UOHDfNu2o5/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFxEAAwEAAAAAAAAAAAAAAAAAARARQf/aAAgBAgEBPwE3F//EABgQAQADAQAAAAAAAAAAAAAAABEAARAh/9oACAEBAAY/AiPMYFZ//8QAGRABAQADAQAAAAAAAAAAAAAAEQEAEDFR/9oACAEBAAE/IQ13DHxErqsYGe5YEmv/2gAMAwEAAgADAAAAEJAP/8QAFxEAAwEAAAAAAAAAAAAAAAAAARARQf/aAAgBAwEBPxATV//EABcRAQADAAAAAAAAAAAAAAAAAAEQEUH/2gAIAQIBAT8QFzEf/8QAGhABAQEBAAMAAAAAAAAAAAAAARExABBhgf/aAAgBAQABPxBT4VK85kxhoXPXXgDWEU6gJa6vxc8f/9k='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"ny flag\"\n        title=\"ny flag\"\n        src=\"/static/c286ab33ffd1fa71c9ea2ceb2fa93015/85f30/ny-flag.jpg\"\n        srcset=\"/static/c286ab33ffd1fa71c9ea2ceb2fa93015/4d5fb/ny-flag.jpg 166w,\n/static/c286ab33ffd1fa71c9ea2ceb2fa93015/85f30/ny-flag.jpg 275w\"\n        sizes=\"(max-width: 275px) 100vw, 275px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), "\n", React.createElement(_components.p, null, "The region that would become New York was previously inhabited by the Native American tribes of the ", React.createElement(_components.strong, null, "Algonquin"), " and ", React.createElement(_components.strong, null, "Iroquois"), ". "), "\n", React.createElement(_components.p, null, "In the early 1600’s the Dutch explored and named much of the area but later gave up the lands to the British. "), "\n", React.createElement(_components.p, null, "In the late 1600’s New York was named for ", React.createElement(_components.strong, null, "James Stuart"), " who, at the time, was the ", React.createElement(_components.strong, null, "Duke of York"), " (a title of nobility). "), "\n", React.createElement(_components.p, null, "James Stuart would go on to become James II, ", React.createElement(_components.strong, null, "King of England"), ". "), "\n", React.createElement(_components.p, null, "Much later, in the 1920’s, a now booming New York City picked up the nickname \"the big apple\" via a writer for the New York Morning Telegraph who, at the time, was writing about horse racing. "), "\n", React.createElement(_components.p, null, "The writer’s name was ", React.createElement(_components.strong, null, "John J. Fitz Gerald"), " and he continued referring to New York City as “the Big Apple” in later writings as well as other writers in different contexts.  "), "\n", React.createElement(_components.p, null, "Later in the 1930’s “the Big Apple” became a popular song and dance."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
